/* eslint-disable require-jsdoc */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import AppLoadingContainer from 'containers/AppLoading/AppLoadingContainer';
import PageLoading from 'components/PageLoading/PageLoading';
import FlashNotification from 'components/FlashNotification/FlashNotification';
import { showFlashNotification, hideFlashNotification } from 'redux/modules/flashNotification';
import 'styles/style.css';

PrivateRoute.propTypes = {
    component: PropTypes.func.isRequired,
    isAuthed: PropTypes.bool.isRequired
};

function PrivateRoute({ component: Component, isAuthed, dispatch, ...rest }) {
    if (!isAuthed) {
        dispatch(showFlashNotification({
            text: 'You need to be logged in to access this page.',
            isWarning: true
        }));
    }

    return (
        <Route {...rest} render={(routeProps) => (
            isAuthed
                ? <Component {...routeProps} />
                : <Redirect to={{
                    pathname: '/login',
                    state: { from: routeProps.location }
                }}/>
        )}/>
    );
}

function PageLoadable(opts) {
    return Loadable(Object.assign({
        loading: PageLoading,
    }, opts));
}

const AsyncHome = PageLoadable({ loader: () => import('containers/PaymentRequest/PaymentRequest') });
const AsyncLogin = PageLoadable({ loader: () => import('containers/Login/LoginContainer') });
const AsyncNotFoundPage = PageLoadable({ loader: () => import('containers/NotFoundPage/NotFoundPage') });
const AsyncOnboarding = PageLoadable({ loader: () => import('containers/Onboarding/Onboarding') });
const AsyncReader = PageLoadable({ loader: () => import('containers/QRReader/QRReader') });
const AsyncRquests = PageLoadable({ loader: () => import('containers/Requests/Requests') });

class AppContainer extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        isAuthed: PropTypes.bool.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        flashNotificationIsPermanent: PropTypes.bool.isRequired,
        flashNotificationLocation: PropTypes.string.isRequired,
        flashNotificationText: PropTypes.string.isRequired,
        flashNotificationIsWarning: PropTypes.bool.isRequired,
        showFlashNotification: PropTypes.bool.isRequired,
    }

    handleHideNotification = () => {
        this.props.dispatch(hideFlashNotification());
    }

    render() {
        const { isAuthed, dispatch, location } = this.props;

        return (
            <React.Fragment>
                <AppLoadingContainer location={location}>
                    <Switch>
                        <Route path='/login' component={AsyncLogin} />

                        <Route
                            path='/'
                            exact={true}
                            component={AsyncReader}
                            dispatch={dispatch}
                            isAuthed={isAuthed} />
                        <PrivateRoute
                            path='/payment'
                            component={AsyncHome}
                            dispatch={dispatch}
                            isAuthed={isAuthed} />
                        <PrivateRoute path='/requests'
                            component={AsyncRquests}
                            dispatch={dispatch}
                            isAuthed={isAuthed} />
                        <PrivateRoute
                            path='/onboarding'
                            component={AsyncOnboarding}
                            dispatch={dispatch}
                            isAuthed={isAuthed} />

                        <Route component={AsyncNotFoundPage} />
                    </Switch>
                </AppLoadingContainer>

                <FlashNotification
                    showFlashNotification={this.props.showFlashNotification}
                    text={this.props.flashNotificationText}
                    location={this.props.flashNotificationLocation}
                    permanent={this.props.flashNotificationIsPermanent}
                    isWarning={this.props.flashNotificationIsWarning}
                    onHideNotification={this.handleHideNotification} />
            </React.Fragment>
        );
    }
}

function mapStateToProps({ authentication, flashNotification }) {
    return {
        isAuthed: authentication.isAuthed,
        flashNotificationText: flashNotification.text,
        flashNotificationLocation: flashNotification.location,
        flashNotificationIsPermanent: flashNotification.permanent,
        flashNotificationIsWarning: flashNotification.isWarning,
        showFlashNotification: flashNotification.showFlashNotification,
    };
}

export default withRouter(
    connect(
        mapStateToProps
    )(AppContainer)
);
