const Eloquent = window.Eloquent;

/**
 * Register user with data
 * @param {String} name
 * @param {String} email
 * @param {String} password
 * @param {String} username
 * @param {String} account_number
 * @param {String} bank
 * @returns {Promise}
 */
export function register(name, email, password, username, account_number, bank) {
    return Eloquent.Auth.register(name, email, password, username, account_number, bank);
}


/**
 * Login user with email and password
 * @param {String} email
 * @param {String} password
 * @returns {Promise}
 */
export function login(email, password) {
    return Eloquent.Auth.login(email, password);
}


/**
 * Logout user
 * @returns {Promise}
 */
export function logout() {
    return Eloquent.Auth.logout();
}


/**
 * Login user with social account
 * @param {String} provider - facebook || google
 * @param {String} token - provided by Facebook/Google
 * @returns {Promise}
 */
export function socialLogin(provider, token) {
    return Eloquent.Auth.socialLogin(provider, token);
}


/**
 * Check if email is registered in the system
 * @param {String} email
 * @returns {Promise}
 */
export function checkEmail(email) {
    return Eloquent.Auth.checkEmail(email);
}


/**
 * Check if the user is logged in
 * @returns {Promise}
 */
export function checkAuthStatus() {
    return Eloquent.Auth.checkLoginStatus();
}

/**
 * Send request password code
 * @param {String} email
 * @returns {Promise}
 */
export function sendResetPassword(email) {
    return Eloquent.Auth.sendResetPasswordCode(email);
}

/**
 * Reset password
 * @param {String} code
 * @param {String} password
 * @returns {Promise}
 */
export function resetPassword(code, password) {
    return Eloquent.Auth.resetPassword(code, password);
}

/**
 * Verify code for password reset
 * @param {String} code
 * @returns {Promise}
 */
export function checkCode(code) {
    return Eloquent.Auth.checkCode(code);
}
