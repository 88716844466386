const Eloquent = window.Eloquent;

/**
 * Get logged in user's profile data
 * @returns {Promise}
 */
export function getAuthedUserProfile() {
    return Eloquent.User.me();
}


/**
 * Get user data by id
 * @param {Number} id
 * @returns {Promise}
 */
export function getUserById(id) {
    return Eloquent.User.getOne(id);
}


/**
 * Set logged in user's profile data
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function setUserProfile(id, data) {
    return Eloquent.User.updateOne(id, data);
}
