import Storage from './Storage';

const LOGIN_TOKEN_STORAGE_KEY = 'SwiffyAppLoginToken';
let axiosTokenInterceptor = null;

/**
 * Save login token
 * @param {String} loginToken
 */
export function saveLoginToken(loginToken) {
    Storage.setItem(LOGIN_TOKEN_STORAGE_KEY, loginToken);
}


/**
 * Get login token
 * @returns {String}
 */
export function getLoginToken() {
    return Storage.getItem(LOGIN_TOKEN_STORAGE_KEY);
}


/**
 * Delete login token
 */
export function deleteLoginToken() {
    Storage.removeItem(LOGIN_TOKEN_STORAGE_KEY);
    window._axios.interceptors.request.eject(axiosTokenInterceptor);
}


/**
 * Sets the the authorization token for api requests
 */
export function setApiRequestToken() {
    const authToken = localStorage.getItem(LOGIN_TOKEN_STORAGE_KEY);

    if (!authToken) {
        return;
    }

    axiosTokenInterceptor = window._axios.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${authToken}`;

        return config;
    });
}


/**
 * Create a deep copy of an object
 * @param {Object} obj
 * @returns {Object}
 */
export function deepCopyObject(obj) {
    return JSON.parse(JSON.stringify(obj));
}


/**
 * Validate email address
 * @param {String} email
 * @returns {Boolean}
 */
export function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
}


/**
 * Sanitize/format date string so that it is valid in Safari
 * @param {String} dateString
 * @returns {Strinh}
 */
export function sanitizeDateString(dateString) {
    return dateString.replace(/-/g, '/');
}


/**
 * Capitalize a string
 * @param {String} str
 * @returns {String}
 */
export function capitalize(str) {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

/**
 * Check if registered user has filled up all necessary profile info
 * @param {Object} user
 * @returns {Boolean}
 */
export function isOldUser(user) {
    return user.first_name && user.last_name && user.country && user.email;
}


/**
 * Remove duplicate based on object key
 * @param {Array} array
 * @param {String} key
 * @returns {Array}
 */
export function removeDuplicates(array, key) {
    return array.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[key]).indexOf(obj[key]) === pos;
    });
}


/**
 * Get the value of the requested param from the current page url
 * @param {String} queryString - the search query
 * @param {String} param - the requested param
 * @returns {String}
 */
export function getUrlSearchParam(queryString, param) {
    const allQueries = queryString.slice(1).split('&');
    let val = '';

    allQueries.forEach((query) => {
        if (query.indexOf(param) !== -1) {
            val = query.split('=')[1];
        }
    });

    return val;
}


/**
 * Sort data
 * @param {Array} arr
 * @param {String} key
 * @returns {Array}
 */
export function sortData(arr, key) {
    return arr.sort((a, b) => {
        return a[key] - b[key];
    });
}
