/* eslint-disable require-jsdoc */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import Spinner from 'components/Spinner/Spinner';
import { getLoginToken, setApiRequestToken } from 'utils/helpers';
import { getAuthedUserProfile } from 'api/user';
import { isAuthed } from 'redux/modules/authentication';

class AppLoadingContainer extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        children: PropTypes.element.isRequired,
        location: PropTypes.object.isRequired,
    }

    state = {
        appIsReady: false
    }

    async componentDidMount() {
        try {
            const token = getLoginToken();

            if (token !== null) {
                setApiRequestToken(token);

                const { data: profileData } = await getAuthedUserProfile();

                this.props.dispatch(isAuthed(profileData));
            }
        }
        finally {
            this.setState({
                appIsReady: true
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.location !== nextProps.location) {
            return true;
        }

        if (this.state.appIsReady !== nextState.appIsReady) {
            return true;
        }

        return false;
    }


    render() {
        if (this.state.appIsReady) {
            return this.props.children;
        }

        return (
            <main>
                <div className='loading-container text-center'>
                    <Spinner />
                </div>
            </main>
        );
    }
}

export default connect()(AppLoadingContainer);
