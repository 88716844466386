/* eslint-disable require-jsdoc */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Notifications, { notify } from 'react-notify-toast';
import './notification.css';

export default class FlashNotification extends PureComponent {
    static propTypes = {
        showFlashNotification: PropTypes.bool.isRequired,
        length: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
        permanent: PropTypes.bool.isRequired,
        isWarning: PropTypes.bool.isRequired,
        onHideNotification: PropTypes.func.isRequired
    }

    static defaultProps = {
        length: 5000,
        location: 'top',
        permanent: false,
        isWarning: false
    }

    constructor(props) {
        super(props);

        this.notifyOptions = {
            animationDuration: 300
        };
    }

    componentDidUpdate() {
        const { text, isWarning, length, permanent } = this.props;

        if (!this.props.showFlashNotification) {
            notify.hide();

            return;
        }

        notify.show(text, isWarning ? 'error' : 'success', permanent ? -1 : length);

        if (!permanent) {
            setTimeout(() => {
                this.props.onHideNotification();
            }, this.props.length + this.notifyOptions.animationDuration);
        }
    }

    render() {
        return (
            <Notifications options={this.notifyOptions} />
        );
    }
}
